var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "h-100" },
    [
      _c(
        "b-col",
        { staticClass: "text-left", attrs: { cols: "12", sm: "6" } },
        [
          _vm._v(
            " " +
              _vm._s(_vm.startingRecord) +
              " - " +
              _vm._s(_vm.endingRecord) +
              " of " +
              _vm._s(_vm.totalRecords) +
              " transaction"
          ),
          _vm.totalRecords > 1 ? _c("span", [_vm._v("s")]) : _vm._e()
        ]
      ),
      _c(
        "b-col",
        { attrs: { cols: "12", sm: "6" } },
        [
          _c(
            "b-row",
            { staticClass: "align-middle", attrs: { "align-h": "end" } },
            [
              _c(
                "b-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mb-0 form-inline",
                      attrs: {
                        label: "Per page: ",
                        "label-for": "per-page-select",
                        "label-align": "right",
                        "label-size": "sm"
                      }
                    },
                    [
                      _c("b-form-select", {
                        staticClass: "ml-1",
                        attrs: {
                          id: "per-page-select",
                          options: _vm.perPageOptions,
                          size: "sm"
                        },
                        on: {
                          change: function(v) {
                            _vm.$emit("update-per-page", v)
                          }
                        },
                        model: {
                          value: _vm.form.perpage,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "perpage", $$v)
                          },
                          expression: "form.perpage"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "auto" } },
                [
                  _c("b-pagination", {
                    staticClass: "my-0",
                    attrs: {
                      "total-rows": _vm.totalRecords,
                      "per-page": _vm.perPage,
                      align: "fill",
                      size: "sm",
                      "aria-controls": _vm.tableName
                    },
                    on: {
                      input: function(v) {
                        _vm.$emit("update-page", v)
                      }
                    },
                    model: {
                      value: _vm.form.page,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "page", $$v)
                      },
                      expression: "form.page"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }