var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-overlay",
    { attrs: { show: _vm.loading } },
    [
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            { staticClass: "mb-4" },
            [
              _c(
                "b-col",
                { staticClass: "text-left text--white" },
                [
                  _c("h4", [_vm._v("Transaction Feedback")]),
                  _c(
                    "b-row",
                    [
                      this.role !== "user"
                        ? _c("b-col", { attrs: { cols: "6" } }, [
                            _c("div", { staticClass: "mb-4 mt-6" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.searchBKQuery,
                                    expression: "searchBKQuery"
                                  }
                                ],
                                staticClass:
                                  "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",
                                attrs: {
                                  id: "employee_search",
                                  type: "text",
                                  placeholder: "Enter Employee Name"
                                },
                                domProps: { value: _vm.searchBKQuery },
                                on: {
                                  focus: function($event) {
                                    return _vm.showBookKeeperList()
                                  },
                                  blur: function($event) {
                                    return _vm.hideBKList()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.searchBKQuery = $event.target.value
                                  }
                                }
                              }),
                              _vm.showBKList
                                ? _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "absolute z-10 w-full mt-1 rounded-md shadow-lg max-height-60 overflow-y-scroll list-none no-list-style custom-bg-gray custom-text-gray"
                                    },
                                    _vm._l(_vm.filteredEmployees, function(
                                      employee,
                                      index
                                    ) {
                                      return _c(
                                        "li",
                                        {
                                          key:
                                            "employee-" +
                                            employee.id +
                                            "-" +
                                            index,
                                          staticClass:
                                            "cursor-pointer p-3 custom-bg-gray custom-text-gray",
                                          on: {
                                            click: function($event) {
                                              return _vm.updateSelectedEmployee(
                                                employee
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(employee.fullname) +
                                              " "
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e()
                            ])
                          ])
                        : _vm._e(),
                      _c("b-col", { attrs: { cols: "6" } }, [
                        _c("div", { staticClass: "mb-4 mt-6" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchQuery,
                                expression: "searchQuery"
                              }
                            ],
                            staticClass:
                              "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",
                            attrs: {
                              id: "company_search",
                              type: "text",
                              placeholder: "Enter Company Name"
                            },
                            domProps: { value: _vm.searchQuery },
                            on: {
                              focus: function($event) {
                                return _vm.showCompanyList()
                              },
                              blur: function($event) {
                                return _vm.hideList()
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.searchQuery = $event.target.value
                              }
                            }
                          }),
                          _vm.showList
                            ? _c(
                                "ul",
                                {
                                  staticClass:
                                    "absolute z-10 w-full mt-1 rounded-md shadow-lg max-height-60 overflow-y-scroll list-none no-list-style custom-bg-gray custom-text-gray"
                                },
                                _vm._l(_vm.filteredCompanies, function(
                                  company,
                                  index
                                ) {
                                  return _c(
                                    "li",
                                    {
                                      key:
                                        "company-" + company.id + "-" + index,
                                      staticClass:
                                        "cursor-pointer p-3 custom-bg-gray custom-text-gray",
                                      on: {
                                        click: function($event) {
                                          return _vm.updateSelectedCompany(
                                            company
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(" " + _vm._s(company.name) + " "),
                                      company.unread
                                        ? _c(
                                            "b-badge",
                                            {
                                              staticStyle: {
                                                "background-color": "#BE191E",
                                                color: "white"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(company.unread) +
                                                  " Unread "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            : _vm._e()
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm.companies.length > 1
                    ? _c("div", { staticClass: "form-inline" })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "text-right" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        pill: "",
                        variant: "outline-secondary",
                        disabled: _vm.loading
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.search.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("b-icon-arrow-counterclockwise", {
                        staticClass: "align-middle"
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.results && _vm.searched
            ? _c(
                "b-card",
                {
                  staticClass: "text--black",
                  attrs: { "bg-variant": "light" }
                },
                [
                  _c("TransactionList", {
                    attrs: {
                      transactions: _vm.transactions,
                      status: _vm.status,
                      amount: _vm.amount,
                      total: _vm.total,
                      "current-page": _vm.currentPage,
                      "per-page": _vm.perPage,
                      sort: _vm.sort,
                      role: _vm.role,
                      "table-name": "needsinfoTable",
                      heading: "Transactions Requiring Feedback"
                    },
                    on: {
                      refresh: _vm.search,
                      update: _vm.updateMessage,
                      "update-page": _vm.updatePage,
                      "update-per-page": _vm.updatePerPage,
                      "sort-changed": _vm.updateSort
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }