var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.transactions
    ? _c(
        "b-overlay",
        { attrs: { show: _vm.loading } },
        [
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "text-left" }, [
                _c("h2", [_vm._v(_vm._s(_vm.heading))])
              ])
            ],
            1
          ),
          _c("b-table", {
            staticClass: "small",
            attrs: {
              id: _vm.tableName,
              fields: _vm.headers,
              items: _vm.transactions,
              "sort-by": _vm.sort.column,
              "sort-desc": _vm.sort.reverse,
              bordered: "",
              "sticky-header": "600px",
              "show-empty": "",
              "primary-key": "id",
              "tbody-tr-class": "align-middle",
              "details-td-class": "w-100 p-0",
              "tfoot-tr-class": "align-middle",
              striped: ""
            },
            on: {
              "update:sortBy": function($event) {
                return _vm.$set(_vm.sort, "column", $event)
              },
              "update:sort-by": function($event) {
                return _vm.$set(_vm.sort, "column", $event)
              },
              "update:sortDesc": function($event) {
                return _vm.$set(_vm.sort, "reverse", $event)
              },
              "update:sort-desc": function($event) {
                return _vm.$set(_vm.sort, "reverse", $event)
              },
              "row-clicked": function(item) {
                return _vm.toggleDetailRow(item)
              },
              "sort-changed": function(ctx) {
                return _vm.$emit("sort-changed", ctx)
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "cell(qbtype)",
                  fn: function(data) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(data.item.qbtype.replace(/([A-Z])/g, " $1")) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: "cell(amount)",
                  fn: function(data) {
                    return [
                      _c("strong", [
                        _vm._v(_vm._s(_vm._f("currency")(data.item.amount)))
                      ])
                    ]
                  }
                },
                {
                  key: "cell(started)",
                  fn: function(data) {
                    return [
                      data.item.messagesunread > 0
                        ? _c("span", [
                            _c("strong", [
                              _vm._v(
                                "Unread (" +
                                  _vm._s(data.item.messagesunread) +
                                  ")"
                              )
                            ])
                          ])
                        : !data.item.messages.length
                        ? _c("span", [_vm._v("Needs Info")])
                        : data.item.messagesneedresponse
                        ? _c("span", [_vm._v("Needs Response")])
                        : _c("span", [_vm._v("Responded")])
                    ]
                  }
                },
                {
                  key: "cell(id)",
                  fn: function(data) {
                    return [
                      _c("b-icon", {
                        staticClass: "pointer",
                        attrs: {
                          icon: data.detailsShowing
                            ? "chevron-up"
                            : "chevron-down"
                        }
                      })
                    ]
                  }
                },
                {
                  key: "row-details",
                  fn: function(row) {
                    return [
                      _c("Messaging", {
                        attrs: { transaction: row.item, role: _vm.role },
                        on: {
                          toggle: row.toggleDetails,
                          update: _vm.updateMessage,
                          refresh: function($event) {
                            return _vm.$emit("refresh")
                          }
                        }
                      })
                    ]
                  }
                },
                _vm.total > 0
                  ? {
                      key: "custom-foot",
                      fn: function(data) {
                        return [
                          _c(
                            "b-tr",
                            { staticClass: "align-middle" },
                            [
                              _c(
                                "b-td",
                                { attrs: { colspan: +data.columns } },
                                [
                                  _c("TransactionPagination", {
                                    staticClass: "m-0",
                                    attrs: {
                                      "current-page": _vm.currentPage,
                                      "per-page": _vm.perPage,
                                      "total-records": _vm.total,
                                      "table-name": _vm.tableName
                                    },
                                    on: {
                                      "update-page": function(v) {
                                        _vm.$emit("update-page", v)
                                      },
                                      "update-per-page": function(v) {
                                        _vm.$emit("update-per-page", v)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  : null
              ],
              null,
              true
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }